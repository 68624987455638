import React from 'react';
import PropTypes from 'prop-types';
import Tag from 'components/tag';
import { ariaRole } from 'components/util/aria';
import { REPORT_REASON } from './reason';

export const ActionSheetReport = (props) => (
    <Tag.div className="operations_area_report">
        {REPORT_REASON.map(item => (
            <Tag.span
                className="operations_area_report__item"
                key={item.key}
                {...ariaRole('button')}
                onClick={event => props.onReport(item.key)}
            >{item.value}</Tag.span>
        ))}
    </Tag.div>
);

ActionSheetReport.propTypes = {
    onReport: PropTypes.func
};

ActionSheetReport.defaultProps = {
    onReport(value) { console.log(`onReport 未绑定，举报内容是 ${value}`) }
};

