import React, { useState, useEffect } from 'react';
import Tag from 'components/tag';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { ariaRole } from 'components/util/aria';
import {
  get_api_labels_overview as getApiLabelsOverview,
} from 'components/api';

import './post-list-label.less';

const LABELS = {
  INIT: 'init',
  DEFAULT: 'default',
  GOOD: 'good',   // 好问题
  SHOW: 'show',   // 最新帖子
  HIDDEN: 'hidden',   // 隐藏帖子
  POPULAR: 'popular', // 话题最热
  NEWEST: 'newest', // 话题最新
};

const PostListLabel = (props) => {
  const [currentLabel, setCurrentLabel] = useState(props.label);
  const [enabledLabels, setEnabledLabels] = useState([LABELS.DEFAULT, LABELS.SHOW]);
  const [topicLabels, setTopicLabels] = useState([LABELS.NEWEST, LABELS.POPULAR]);


  /**
     * 是否显示好问题标签
     */
  useEffect(() => {
    getApiLabelsOverview({ productId: props.productId, readInfo: true }).then((resp) => {
      if (resp.data.good_post.total) {
        setEnabledLabels(preState => ([...preState, LABELS.GOOD]));
      }
      if (resp.data.hidden_post) {
        setEnabledLabels(preState => ([...preState, LABELS.HIDDEN]));
      }
    });
  }, [props.productId]);


  // 点击标签
  const onClickLabel = (key) => {
    if (currentLabel === key) {
      return;
    }

    setCurrentLabel(key);
    props.onChange(key);
  };

  const defaultLabels = [
    { key: LABELS.DEFAULT, text: '默认' },
    { key: LABELS.SHOW, text: '最新' },
    { key: LABELS.GOOD, text: '好问题' },
    { key: LABELS.HIDDEN, text: '被隐藏' },
    { key: LABELS.POPULAR, text: '最热' },
    { key: LABELS.NEWEST, text: '最新' },
  ];

  const labels = props.topic
    ? defaultLabels.filter(item => topicLabels.includes(item.key))
    : defaultLabels.filter(item => enabledLabels.includes(item.key));

  return (
    <Tag.div className="post_list_label">
      <Tag.div className="label_list" {...ariaRole('tablist')}>
        {labels.map(item => (
          <Tag.a
            key={item.key}
            {...ariaRole('tab')}
            aria-selected={currentLabel === item.key}
            className={classNames('label_list__item', { 'label_list__item--native': currentLabel === item.key })}
            onClick={() => onClickLabel(item.key)}
            href={`#label=${item.key}`}
          >
            {item.text}
          </Tag.a>
        ))}

      </Tag.div>
    </Tag.div>
  );
};

PostListLabel.propTypes = {
  // 是否话题列表
  topic: PropTypes.bool,
  /**
     * 切换标签
     */
  onChange: PropTypes.func,
  /**
     * 当前选中的标签
     */
  label: PropTypes.string,
  /**
     * 产品 ID
     */
  productId: PropTypes.string.isRequired,
  /**
     * 用户信息
     */
  userData: PropTypes.shape({
    user_id: PropTypes.number,
    is_admin: PropTypes.bool,
  }),
};

export { PostListLabel, LABELS };
