import React, { createContext, useEffect, useMemo, useState } from 'react';
import { getProductId, localStorage } from 'components/util';

export const useOutsideHandler = (ref, ref2, toExpand, setToExpand) => {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target) && toExpand) {
        if (!ref2 || (ref2.current && !ref2.current.contains(event.target))) {
          setToExpand(false);
        }
      }
    }

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, ref2, setToExpand, toExpand]);
};


export const convertNumber = (number) => {
  const parsedNumber = Math.abs(Number(number));
  if (parsedNumber >= 1.0e+9) {
    return `${(parsedNumber / 1.0e+9).toFixed(1)} B`;
  }
  if (parsedNumber >= 1.0e+6) {
    return `${(parsedNumber / 1.0e+6).toFixed(1)} M`;
  }
  if (parsedNumber >= 1.0e+3) {
    return `${(parsedNumber / 1.0e+3).toFixed(1)} K`;
  }
  return parsedNumber;
};

export const handleOnListClick = (event, reactions, setReactions, setIsAddingReaction) => {
  const clickElement = event.target.closest('[data-index]');
  if (!clickElement) return;
  const index = parseInt(clickElement.dataset.index, 10);
  const reaction = reactions[index];
  if (!reaction) return;
  reaction.count += reaction.isSelected ? -1 : 1;
  reaction.isSelected = !reaction.isSelected;
  setReactions([...reactions]);
  setIsAddingReaction(false);
};

export const isClickingBottom = event => window.innerHeight - event.clientY < 150;

export const renderTag = (comment, userData, authorId, cls) => {
  if (comment.is_admin) {
    return <span className={`${cls}__tag ${cls}__tag--official ${cls}-detail-comment__author--right`}>官方</span>;
  }
  if (authorId === comment.user_id) {
    return <span className={`${cls}__tag ${cls}__tag--author ${cls}-detail-comment__author--right`}>作者</span>;
  }
  if (userData.user_id === comment.user_id) {
    return <span className={`${cls}__tag ${cls}__tag--self ${cls}-detail-comment__author--right`}>我的</span>;
  }
  return null;
};

export const isLessThanOneWeek = time => (Date.now() / 1000 - parseInt(time, 10)) < 640800;

export const useIsInViewport = (ref) => {
  const [isIntersecting, setIsIntersecting] = useState(false);

  const observer = useMemo(() => new IntersectionObserver(([entry]) => {
    setIsIntersecting(entry.isIntersecting);
  }, { rootMargin: '-120px 0px 0px 0px' }), []);

  useEffect(() => {
    observer.observe(ref.current);
    return () => {
      observer.disconnect();
    };
  }, [ref, observer]);

  return isIntersecting;
};

const bloomFilterTableSize = 97;

const hashFunctions = [
  value => (value ** 2 * 23 + 391) % bloomFilterTableSize,
  value => (value ** 3 * 14 + value ** 2 * 63 + 149) % bloomFilterTableSize,
  value => (value ** 4 * 52 + value ** 3 * 26 + value ** 2 * 82 + 284) % bloomFilterTableSize,
];
export const bloomFilterLookup = (changeLogId) => {
  if (!changeLogId) return false;
  const productId = getProductId();
  const digits = localStorage.getItem(`changelog${productId}`);
  if (!digits) return true;
  for (const hashFunction of hashFunctions) {
    const index = hashFunction(parseInt(changeLogId, 10));
    if (digits[index] === '0') return true;
  }
  return false;
};

export const bloomFilterInsert = (changeLogId) => {
  const productId = getProductId();
  const key = `changelog${productId}`;
  let digits = localStorage.getItem(key);
  if (!digits) {
    digits = '0'.repeat(bloomFilterTableSize);
    localStorage.setItem(key, digits);
  }
  const arr = digits.split('');
  for (const hashFunction of hashFunctions) {
    const index = hashFunction(parseInt(changeLogId, 10));
    arr[index] = '1';
  }
  localStorage.setItem(key, arr.join(''));
};

// 获取当前窗口下所有可视的指定类名的元素
export const getVisibleElementsWithClass = (className, offsetTop) => {
  // Get all elements with the class
  const elements = Array.from(document.getElementsByClassName(className));

  // Filter them to get only the ones that are visible in the viewport
  return elements.filter((element) => {
    const rect = element.getBoundingClientRect();
    return (
      rect.top >= offsetTop
      && rect.left >= 0
      && rect.bottom <= (window.innerHeight || document.documentElement.clientHeight)
      && rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
  });
};

// 与上一个方法类似，但是元素不需要完全在视窗内，只要有一部分在视窗内就可以
export const getElementsInViewport = (className) => {
  // Get all elements with the class
  const elements = Array.from(document.getElementsByClassName(className));

  // Filter them to get only the ones that are in the viewport
  return elements.filter((element) => {
    const rect = element.getBoundingClientRect();
    return (
      rect.top <= (window.innerHeight || document.documentElement.clientHeight)
      && rect.left <= (window.innerWidth || document.documentElement.clientWidth)
      && rect.bottom >= 0
      && rect.right >= 0
    );
  });
};

export const RenderLinkContext = createContext(null);

