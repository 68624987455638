/* eslint-disable camelcase, react/prop-types */
import React, { Component, Fragment } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { isPlainObject, throttle } from 'lodash-es';
import { ImageAlbum } from '@tencent/coral-design';
import { Avatar } from 'components/user';
import { PostLabel } from 'components/post-label';
import { ImageDesktop } from 'components/comment-image/image-desktop';
import { UserApprec } from 'components/user-apprec';
import { href_profile, href_post, ceGroupName } from 'components/href-helper';
import { UserEnter, BtnPublic } from 'components/user-enter';
import { postsTime, getProductId, getIWantContent, getChangeLogContent } from 'components/util';
import SupportBtn, { TYPE as SUPPORT_BTN_TYPE } from 'components/ui/common/support-btn';
import {
  QQ_PRODUCT_ID,
  QQ_ANDROID_PRODUCT_ID,
  QQ_IOS_PRODUCT_ID,
  POST_TYPE,
} from 'components/constants/app';
import { DescPara } from './desc-para.desktop';
import { PostOperations } from './post-operations.desktop';
import { CommentItemPc } from './comment.desktop';
import { TagShow } from 'components/tag-show';
import Topic from 'components/topic/topic-container';
import './post-item.desktop.less';
import { ChangeLog } from 'components/change-log-pc';

class PostItemPc extends Component {
  constructor(props) {
    super(props);
    this.productId = getProductId();
    // NOTE: QQ产品希望回复时默认不取消隐藏，所以整些hard code
    const isQQ = [QQ_PRODUCT_ID, QQ_ANDROID_PRODUCT_ID, QQ_IOS_PRODUCT_ID].includes(Number(this.productId));
    this.handleClickLike = throttle(this.handleClickLike.bind(this), 300, { leading: true });
    this.state = {
      // qq默认false , 非qq默认true
      checked_disabled_hidden: !isQQ,
      show_image_album: null,
    };
    this.handleImagesClick = this.handleImagesClick.bind(this);
    this.renderReplyList = this.renderReplyList.bind(this);
    this.handleClickReply = this.handleClickReply.bind(this);
    this.scrollToAnchor = this.scrollToAnchor.bind(this);
  }

  handleImagesClick(i) {
    this.setState({ show_image_album: i });
  }

  handleClickLike(e) {
    const { onClickLike } = this.props;
    onClickLike(e);
  }
  // 点击评论按钮
  handleClickComment() {
    const { props } = this;
    props.onClickComment(props.show_comment_textarea);
    props.onClickReply(true);
    this.scrollToAnchor(props.id);
  }

  // 点击回复按钮
  handleClickReply(clickAgain, item) {
    const { props } = this;
    if (props.is_locked) return;
    const reply_id = item.f_reply_id || item.id;
    props.onClickComment(true);
    props.onClickReply(clickAgain, reply_id);
  }

  // 点击评论描点
  scrollToAnchor(anchorName) {
    if (this.props.show_comment_textarea) {
      return;
    }
    const anchorElement = document.getElementById(anchorName);
    anchorElement.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
      inline: 'nearest',
    });
  }

  getLikeList() {
    const { like_list: likeList } = this.props;
    if (!likeList) {
      return '';
    }
    let str = likeList.join('、');
    if (str.length > 45) {
      str = `${str.slice(0, 45)}...`;
    }
    return str;
  }

  // 返回显示标签
  getTag(isAdmin, isAuthorId) {
    if (isAdmin) {
      return <span className="tag__admin">官方</span>;
    }
    if (isAuthorId) {
      return <span className="tag__author">作者</span>;
    }
    return null;
  }

  // 回复模块列表渲染
  renderReplyList(replies_list, hasTipBatch) {
    const { state, props } = this;
    const agreedReply = state.checked_disabled_hidden;
    const hidenPost = Boolean(props.user.is_admin && props.is_hidden);
    const renderLink = props.productData.ugc_url_status;

    return (
      <div className="comment_outline">
        {hasTipBatch && props.productData.tip_status === 'on' && <UserApprec {...props.tipBatchList[props.id]} />}

        <div className="comment_module">
          <div className="comment_list" style={{ display: replies_list.length ? 'block' : 'none' }}>
            {replies_list.map((item, index) => {
              // 是否显示回复区域
              const show_reply_textarea = Boolean(props.showReplyBox === (item.f_reply_id || item.id));
              const replyId = item.f_reply_id || item.id;
              const parent = props.replies[item.parent_reply_id] || item.parent;
              const f_reply_text = item.content || item.f_reply_text;

              return (
                <Fragment key={index}>
                  <CommentItemPc
                    {...item}
                    postUserId={props.user_id}
                    onClickMenu={props.onClickMenu}
                    onClickDelete={props.onDeleteComment}
                    likedInfo={props.repliesLiked[replyId]}
                    userData={props.userData}
                    is_locked={props.is_locked}
                    parent={parent}
                    f_reply_text={f_reply_text}
                    onReply={() => this.handleClickReply(show_reply_textarea, item)}
                    onRepliesLiked={likedObj => props.onRepliesLiked(likedObj, replyId)}
                    onCheckLogin={props.onCheckLogin}
                    tagArrList={props.tagArrList}
                    changePostsList={props.changePostsList}
                    renderLink={renderLink}
                  />
                  {show_reply_textarea && (
                    <div className="comment_form">
                      <Avatar src={props.userData.avatar} />
                      <UserEnter
                        textbtn="回复"
                        placeholder={`回复${item.nick_name}`}
                        disabled={props.is_locked}
                        External={
                          <div className="text_func_public">
                            {hidenPost && (
                              <BtnPublic
                                checked={agreedReply}
                                onChange={() => {
                                  this.setState({
                                    checked_disabled_hidden: !agreedReply,
                                  });
                                }}
                              />
                            )}
                          </div>
                        }
                        onSubmit={(value, images) => props.onCommentSubmit({
                          value,
                          images,
                          replyId,
                          cancelHidden: Boolean(agreedReply && hidenPost),
                        })
                        }
                        onUploadImage={props.onUploadImage}
                        onError={props.onCommentError}
                      />
                    </div>
                  )}
                </Fragment>
              );
            })}
          </div>
          {(props.reply_count || props.reply_num) > 3 && Boolean(replies_list.length >= 3) && (
            <a className="comment_total" target="_blank" href={href_post(props.id)} rel="noreferrer">
              查看全部 {props.reply_num || props.reply_count} 条评论
            </a>
          )}
          <div id={props.id}>
            {props.show_comment_textarea && (
              <div className="comment_form">
                <Avatar src={props.userData.avatar} />
                <UserEnter
                  textbtn="评论"
                  placeholder={'别害羞，快来说两句吧…'}
                  disabled={props.is_locked}
                  onSubmit={(value, images) => props.onCommentSubmit({
                    value,
                    images,
                    cancelHidden: Boolean(agreedReply && hidenPost),
                  })
                  }
                  onError={props.onCommentError}
                  onUploadImage={props.onUploadImage}
                  External={
                    <div className="text_func_public">
                      {props.user.is_admin && props.is_hidden && (
                        <BtnPublic
                          checked={agreedReply}
                          onChange={() => {
                            this.setState({
                              checked_disabled_hidden: !agreedReply,
                            });
                          }}
                        />
                      )}
                    </div>
                  }
                />
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }

  renderIWant() {
    const { like_count: likeCount, content: postContent, like_list: likeList } = this.props;
    const { title, content, solution } = getIWantContent(postContent);
    return (
      <>
        {!!likeCount && likeList && likeList.length !== 0 && (
          <div className="post_item_pc__like-list">
            <span>{this.getLikeList()}</span>
            <span>{likeCount > 1 ? `等${likeCount}人` : ''}所见略同</span>
          </div>
        )}
        <div className="post_item_pc__title">我想要</div>
        <div className="post_item_pc__title-main">
          <DescPara desc={title} renderLink={this.props.productData.ugc_url_status === 'on' || this.props.is_admin} />
        </div>
        <div className="post_item_pc__content">
          <span className="post_item_pc__emoji" aria-hidden>
            😤&nbsp;
          </span>
          目前痛点
        </div>
        <div className="post_item_pc__content-main">
          <DescPara desc={content} renderLink={this.props.productData.ugc_url_status === 'on' || this.props.is_admin} />
        </div>
        <div className="post_item_pc__solution">
          <span className="post_item_pc__emoji" aria-hidden>
            💡&nbsp;
          </span>
          我的想法
        </div>
        <div className="post_item_pc__solution-main">
          <DescPara desc={solution} renderLink={this.props.productData.ugc_url_status === 'on' || this.props.is_admin} />
        </div>
      </>
    );
  }

  renderContent() {
    if (this.props.type === POST_TYPE.I_WANT) {
      return this.renderIWant();
    }
    if (
      this.props.type === POST_TYPE.CHANGE_LOG
    ) {
      const { content: postContent } = this.props;
      const changeLog = getChangeLogContent(postContent);
      changeLog.showMask = true;
      changeLog.date = this.props.created_at_timestamp;
      changeLog.id = this.props.id;
      return <ChangeLog changeLog={changeLog} style={{ maxHeight: '500px', overflow: 'hidden', marginTop: '8px' }} productId={this.props.productData.id} />;
    }
    return (
      <DescPara desc={this.props.content} renderLink={this.props.productData.ugc_url_status === 'on' || this.props.is_admin} />
    );
  }

  render() {
    const { state, props } = this;
    let replies_list = [];
    if (isPlainObject(props.replies)) {
      replies_list = Object.keys(props.replies).map(postId => props.replies[postId]);
    }

    const hasReplies = Boolean(replies_list.length);
    const hasTipBatch = isPlainObject(props.tipBatchList[props.id]);

    // 视觉零界状态处理
    // 打赏人为0 ， 评论数为0
    const is_aType = Boolean(!hasTipBatch && props.replies.length === 0 && !props.show_comment_textarea);
    // 打赏人不为0 评论数为0
    const is_bType = Boolean(hasTipBatch && props.replies.length === 0 && !props.show_comment_textarea);
    // 判断是否是i want
    const isWantPost = props.type === POST_TYPE.I_WANT;
    return (
      <div
        className={classNames('post_item_pc', {
          hide_aType: is_aType || !hasReplies,
          hide_bType: is_bType,
          'post_item_pc--want': isWantPost,
        })}
      >
        <div className="item_inner">
          <div className="user_avatar">
            <a href={href_profile(props.user_id)} target="_blank" rel="noreferrer" className="avatar__link">
              <Avatar src={props.avatar_url} />
            </a>
            {isWantPost && (
              <SupportBtn
                isLiked={props.is_liked}
                count={props.like_count || 0}
                onClick={this.handleClickLike}
                type={SUPPORT_BTN_TYPE.PC}
              />
            )}
          </div>
          <div className="item_content">
            <div className="user_info">
              <a
                href={href_profile(props.user_id)}
                target="_blank"
                className={classNames('user_name', { admin: props.is_admin })}
                rel="noreferrer"
              >
                {props.nick_name}
              </a>
              {this.getTag(props.is_admin, props.user_id === props.user.user_id)}
              {(props.group_name || props.postname) && (
                <div className="public_group_name">{ceGroupName(props.group_name, props.postname)}</div>
              )}
              <div className="public_time">{postsTime(props.created_at_timestamp)}</div>
              <div className="type_label">
                <TagShow tags={props.tags} />
                <PostLabel {...props} />
              </div>
            </div>
            {props.showTopic && (
              <div className="topic_title">
                <Topic productId={this.productId} postId={props.id} isProduct />
              </div>
            )}
            {this.renderContent()}
            {Boolean(props.images.length) && (
              <ImageDesktop imgList={props.images} handleImagesClick={this.handleImagesClick} />
            )}
            <PostOperations
              {...props}
              onCommentBtn={() => this.handleClickComment()}
              onClickLike={this.handleClickLike}
              onRewardCLick={props.onRewardCLick}
              onCheckLogin={props.onCheckLogin}
              onClickMenu={props.onClickMenu}
              onClickDelete={props.onClickDelete}
              rewardInfo={props.rewardInfo}
              productData={props.productData}
              tapdEntry={props.tapdEntry}
              onClickTapd={props.onClickTapd}
              isWantPost={isWantPost}
              tagArrList={props.tagArrList}
              changePostsList={props.changePostsList}
            />
            {this.renderReplyList(replies_list, hasTipBatch)}
          </div>
        </div>
        {state.show_image_album !== null && (
          <ImageAlbum
            imageList={props.images.map(item => item.original_url)}
            previewList={props.images.map(item => item.thumb_url)}
            showPreview={true}
            showIndex={state.show_image_album}
            onClose={() => this.setState({ show_image_album: null })}
          />
        )}
      </div>
    );
  }
}
PostItemPc.propTypes = {
  id: PropTypes.string,
  user_id: PropTypes.number,
  show_comment_textarea: PropTypes.bool,
  is_admin: PropTypes.bool,
  is_hidden: PropTypes.bool,
  is_locked: PropTypes.bool,
  showReplyBox: PropTypes.bool,
  reply_count: PropTypes.number,
  reply_num: PropTypes.number,
  created_at_timestamp: PropTypes.number,
  avatar_url: PropTypes.string,
  content: PropTypes.string,
  nick_name: PropTypes.string,
  group_name: PropTypes.string,
  images: PropTypes.array,
  tipBatchList: PropTypes.object,
  repliesLiked: PropTypes.object,
  replies: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  user: PropTypes.object,
  userData: PropTypes.object,
  productData: PropTypes.object,
  rewardInfo: PropTypes.object,
  onClickComment: PropTypes.func,
  onClickReply: PropTypes.func,
  onRepliesLiked: PropTypes.func,
  onCommentSubmit: PropTypes.func,
  onClickLike: PropTypes.func,
  onRewardCLick: PropTypes.func,
  onCheckLogin: PropTypes.func,
  onClickMenu: PropTypes.func,
  onClickDelete: PropTypes.func,
  onUploadImage: PropTypes.func,
  onCommentError: PropTypes.func,
  onDeleteComment: PropTypes.func,
};
PostItemPc.defaultProps = {
  tapdEntry: false,
  nick_name: '',
  content: '',
  replies: [],
  images: [],
  tip_batch: [],
  tip_total: 0,
  rewardInfo: {},
  tipBatchList: [],
  user: {},
  userData: {},
  repliesLiked: [],
  onClickComment: () => console.log('onClickComment 没绑'),
  onCommentSubmit: () => console.log('onCommentSubmit 没有'),
};

export { PostItemPc };
